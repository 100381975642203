import "./Party.css";
import union from "../asset/img/union.svg";
import party from "../asset/img/banner-sh500ae.webp";

const Party = () => {
  return (
    <div className="Subject">
      <div className="subject-container">
        <div className="line-title-subject">
          <img className="subject-union" src={union} />
          <span className="subject-title">
          ĐẠI TIỆC TRĂNG RẰM - VUI TẾT TRUNG THU CÙNG 500AE
          </span>
        </div>
        <div className="subject-body">
          <div className="subject-item">
            <div className="subject-item-title">
              <img src={party} alt="khuyen mai" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Party;
